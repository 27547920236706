div[form-page] {
  .form-container {
    padding: 4rem 0 4rem 0;
  }

  .form-title {
    padding:  0 0 4rem 0;
    margin:  0;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 1.2em;
  }

  .control-field  {
    width: 100%;
  }

  .form-label {
    letter-spacing: 0.1px;
    line-height: 1.2em;
    white-space: normal;
    font-size: 1.2em;
    font-weight: 600;
    margin: 0 0 8px;
    max-width: 100%;
    padding: 0;
  }

  .form-actions {
    margin: 2rem 0 0 0;
  }
}
